@import '../common/color';

.ant-card.fanthon-card {
  background: initial;
  width: max-content;
}

.fanthon-card {
  .ant-card-body {
    width: 100%;
    border: 1px solid @black-200;
    background: @white 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 0px @shadow;
    border-radius: 12px;
  }
}
