@import '../common/color';

.header-fixed {
  height: initial !important;
  position: fixed !important;
  z-index: 10;
  background: white !important;
  width: 100%;
  .ant-page-header-heading {
    display: block;
    margin: auto;
    .ant-page-header-heading-title {
      display: block;
      margin: auto;
      .ant-row {
        flex-flow: column !important;
        width: 100%;
      }

      .logo_avatar {
        width: 50px;
        height: 50px;
        margin: 0 auto;
        justify-self: center;
        align-self: center;
        background-size: contain;
        margin-bottom: 1rem;
      }
      .title_header {
        font-size: 12px;
      }
      .text_header {
        font-size: 10px;
        line-height: normal;
        white-space: normal;
      }
    }
  }

  .ant-page-header-content {
    padding-top: 0px;
  }

  .progress-level {
    position: fixed;
    left: 0px;
    margin-top: 5px;

    .ant-progress-inner {
      background: rgba(51, 126, 255, 0.3);
    }
    .ant-progress-success-bg,
    .ant-progress-bg {
      border-radius: 0px;
      background: @info-600;
    }
  }
}

.navbar {
  width: 100%;
  display: block;
  background: @black-100;
  position: fixed;
  padding: 10px 20px;
  z-index: 10;
}
@media screen and (min-width: 481px) {
  .header-fixed {
    .ant-page-header-heading {
      .ant-page-header-heading-title {
        .logo_avatar {
          width: 75px;
          height: 75px;
        }
        .title_header {
          font-size: 16px;
        }
        .text_header {
          font-size: 12px;
        }
      }
    }
  }
}
@media screen and (min-width: 600px) {
  .header-fixed {
    .ant-page-header-heading {
      display: flex;
      justify-content: left;
      .ant-page-header-heading-title {
        .ant-row {
          flex-flow: row !important;
          gap: 30px;
        }
        .logo_avatar {
          width: 75px;
          height: 75px;
          margin-bottom: 0;
        }
        .title_header {
          font-size: 20px;
        }
        .text_header {
          font-size: 16px;
        }
      }
    }
  }
}
