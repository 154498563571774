.containerCardBox {
    max-width: 1200px;
    width: 100%;
    padding-inline: 20px;

    @media only screen and (max-width: 620px){
        .ant-typography.fanthon-text__h4{
            font-size: 24px;
            line-height: 32px;
        }
        .ant-typography.fanthon-text__h9{
            font-size: min(14px,3.5vw);
        }
    }
}
.cardLayout {
    display: flex;
    width: '100%';
    justify-content: 'center'
}